<template>
  <v-dialog v-model="drawer" scrollable persistent max-width="800">
    <v-form class="fill-height" @submit.prevent v-if="obModel">
      <gw-form-observer
        @save="save"
        @cancel="cancel"
        :loading="isLoading"
        :dirty="isDirty"
        hide-top-actions
      >
        <settings v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import TaxtypesMixin from "@/modules/taxtypes/mixins/TaxtypesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/taxtypes/components/tabs/Settings.vue";

@Component({
  components: {
    SheetDrawer,
    Settings,
  },
})
export default class TaxtypesForm extends Mixins(TaxtypesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = ["settings"];
}
</script>
