<template>
  <v-row>
    <v-col cols="12" lg="6">
      <form-field-text @save="save" v-model="obTaxType.name" required />
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text
        @save="save"
        v-model="obTaxType.short_name"
        label="short.name"
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text
        @save="save"
        v-model="obTaxType.code"
        label="code"
        required
      />
    </v-col>

    <v-col cols="12" lg="6">
      <form-field-text
        v-model="obTaxType.percent"
        label="value"
        input-type="number"
        @save="save"
      />
    </v-col>

    <v-col cols="12">
      <form-field-text
        @save="save"
        v-model="obTaxType.description"
        label="description"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { TaxType } from "@planetadeleste/vue-mc-gw";

@Component
export default class TaxtypesSettings extends Vue {
  @VModel({ type: Object, default: () => new TaxType() })
  obTaxType!: TaxType;

  save() {
    this.$emit("save", true);
  }
}
</script>
